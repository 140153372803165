<template>
  <div class="form-upload">
    <loading :show="loading_show" />
    <div class="left-label">
      <p>{{label}}</p>
    </div>
    <div class="right-upload">
      <van-uploader v-model="fileList" multiple :after-read="finishedAfterRead" :preview-image="false" class="upload" />
      <div class="bac-img" :style="`background: url(${cdnHttp}${item}) no-repeat center / cover`" v-for="(item,index) in returnList" :key="index" @click="imgPreview(item,index)">
        <div class="delete" @click.stop="deleteImg(item)">×</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";

export default {
  props: {
    label: {
      type: String,
      default: "填写label:",
    },
    finished_images_url: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      fileList: [],
      returnList: [],
      loading_show: false,
    };
  },
  created() {
    this.returnList = this.finished_images_url;
  },
  methods: {
    finishedAfterRead(file) {
      this.loading_show = true;
      let imageFormData = new FormData();
      if (file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          imageFormData.append(`files[${i}]`, file[i].file);
        }
      } else {
        imageFormData.append(`files[0]`, file.file);
      }
      imageFormData.append("order_id", this.$route.params.order_id);
      imageFormData.append("scence", 1);
      imageFormData.append("file_type", 1);
      this.$http
        .post("/api/worker/uploadFinished", imageFormData)
        .then((res) => {
          this.loading_show = false;
          console.log("form-upload-return", res);
          res.data.result.forEach((element) => {
            this.returnList.push(element.url);
            this.$emit("return-url", this.returnList);
          });
        })
        .catch((err) => {
          this.$toast(err.error);
          this.loading_show = false;
        });
    },
    imgPreview(item,index) {
      let _img_List = [];
      this.returnList.forEach((item) => {
        console.log(item);
        _img_List.push(`${this.cdnHttp}${item}`);
      });
      
      ImagePreview({
        images: _img_List,
        startPosition: index,
        closeable: true,
      });
    },
    deleteImg(item) {
      this.returnList.remove(item);
      this.$emit("return-url", this.returnList);
    },
  },
};
</script>

<style lang="less">
.form-upload {
  display: flex;
  padding: 0 1rem;
  .left-label {
    width: 5rem;
    box-sizing: border-box;
    width: 6.2em;
    margin-right: 12px;
    color: #646566;
    text-align: left;
    font-size: 1.4rem;
  }
  .right-upload {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .upload {
      width: 8rem;
      height: 8rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .bac-img {
      width: 8rem;
      height: 8rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      position: relative;

      .delete {
        text-align: center;
        display: inline-block;
        position: absolute;
        width: 2rem;
        height: 2rem;
        background: chocolate;
        right: 0;
        top: 0;
        color: #fff;
        font-size: 1.6rem;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 12px;
      }
    }

    display: flex;
  }
}
</style>