<template>
  <div id="completeAlready" v-if="page_show == 'affirm-main'">
    <van-nav-bar title="确认完工" left-arrow @click-left="goLastPage" :border="false" />
    <div style="marginTop:1rem" class="upload-wrap">
      <form-upload label="完工照上传:" @return-url="getFinishImagesUrl" style="background:#fff;borderBottom: 1px solid #f2f2f2" :finished_images_url="finished_images_url" />
      <van-field name="radio" label="是否穿正装:" style="padding: 10px">
        <template #input>
          <van-radio-group v-model="isFormal" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <form-upload label="工装照上传:" @return-url="getCloseImage" style="background:#fff;borderBottom: 1px solid #f2f2f2" v-if="isFormal == '1'" />
    </div>
    <div>
      <SelectWrap theme="服务签收单" isMust="true" v-if="electronListUrl">
        <template #content>
          <div class="sign_receipt sign_receiptImg">
            <img :src="`${cdnHttp}${electronListUrl}?imageView2/1/format/webp/w/90/h/180/q/90`" alt="" @click="listPreview">
            <div class="replace" @click="replaceList">
              <svg t="1605073327564" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1445" width="22" height="22">
                <path d="M874.024 149.976C774.072 49.992 643.016 0.016 511.984 0.016c-131.024 0-262.048 49.976-362.024 149.952-199.928 199.928-199.928 524.12 0 724.048 99.984 99.984 231.032 149.96 362.056 149.96 131.04 0 262.064-49.976 362.04-149.928 199.928-199.952 199.928-524.144-0.032-724.072z m-42.536 681.52c-85.344 85.304-198.784 132.28-319.48 132.28-120.672 0-234.152-46.976-319.488-132.312-176.152-176.152-176.152-462.776 0-638.92 85.336-85.312 198.784-132.32 319.464-132.32 120.688 0 234.136 47.008 319.472 132.32 176.144 176.144 176.176 462.768 0.032 638.952z" fill="#888888" p-id="1446"></path>
                <path d="M684.152 339.88a30.088 30.088 0 0 0-42.568 0L512 469.44 382.424 339.88c-11.76-11.76-30.808-11.76-42.568 0s-11.76 30.808 0 42.568L469.432 512 339.856 641.552a30.08 30.08 0 0 0 0 42.56 30.016 30.016 0 0 0 21.288 8.824c7.696 0 15.4-2.936 21.28-8.824L512 554.56l129.576 129.552c5.872 5.88 13.576 8.824 21.28 8.824s15.408-2.936 21.288-8.824a30.08 30.08 0 0 0 0-42.56L554.568 512l129.576-129.552a30.096 30.096 0 0 0 0.008-42.568z" fill="#888888" p-id="1447"></path>
              </svg>
            </div>
          </div>
        </template>
      </SelectWrap>
      <SelectWrap theme="服务签收单" isMust="true" v-else>
        <template #content>
          <div class="sign_receipt">
            <div class="selectList" @click="goList">
              <p>在线填写</p>
            </div>
            <div class="selectList">
              <van-uploader :after-read="uploadPageList">
                <p v-if="loading">上传纸质</p>
                <van-loading type="spinner" v-else />
              </van-uploader>
            </div>
          </div>
        </template>
      </SelectWrap>
      <SelectWrap theme="客户验收码" isMust="true">
        <template #content>
          <div>
            <van-password-input :value="completeCode" :mask="false" :focused="showKeyboard" @focus="showKeyboard = true" />
            <van-number-keyboard v-model="completeCode" :show="showKeyboard" @blur="showKeyboard = false" />
            <p class="noCompleteCode" @click="getOtherCode">客户找不到服务确认码怎么办？</p>
          </div>
        </template>
      </SelectWrap>
      <div style="padding-bottom: 60px">
        <van-popup v-model="otherCodeShow" closeable>
          <div class="otherCodeWrap">
            <p class="tip">特殊情况获取不了服务验证码<br />可通过以下方式获取</p>
            <div class="codeType">
              <!-- <van-button plain hairline type="info" block>再次发送验收码至客户</van-button> -->
              <div class="line type1">
                <p>再次向客户发送服务确认码</p>
              </div>
              <div class="line">
                <p>确认码将发送至{{userPhone.custom}}手机上</p>
              </div>
              <div class="line">
                <van-button plain hairline type="info" @click="getCompleteCode">发送短信</van-button>
              </div>
            </div>
            <div class="codeType type2">
              <div class="line">
                <p>联系下单方索取</p>
              </div>
              <div class="line">
                <p>请下单方点击[服务确认码即可]</p>
              </div>
              <div class="line">
                <a :href="`tel://${userPhone.merchant}`">
                  <van-button plain hairline type="info" color="#FF976A">拨打电话</van-button>
                </a>
              </div>
            </div>
          </div>
        </van-popup>
      </div>
    </div>
    <van-button type="primary" block color="linear-gradient(to right, #ff6034, #ee0a24)" text="确定完成" @click="submitEleList" style="position:fixed;bottom:0;left:0;right:0">
    </van-button>
  </div>

  <FinishedList v-else-if="page_show == 'finished-list'" @linkAaffirmFinished="linkAaffirmFinished" @temp-link-main="tempLinkMain" />
</template>
<script>
import FinishedList from "@/components/order/affirm-finished/FinishedList";
import SelectResult from "@/components/effect/SelectResult.vue";
import SelectWrap from "@/components/effect/SelectWrap.vue";
import { ImagePreview } from "vant";
import UploadDate from "@/components/effect/UploadDate.vue";
import FileUpdate from "@/components/effect/FileUpdate.vue";
import FormUpload from "@/components/effect/upload/FormUpload.vue";
/**
 * 子页面组件集合
 */
import ElectronListTable from "@/components/order/finish/ElectronListTable";
export default {
  data() {
    return {
      page_show: "affirm-main",
      electronListUrl: "",
      loading: true,
      isFormal: "2",
      isFormalShow: false,
      noCodeShow: false,
      orderId: "",
      // selectStatu: "",
      video: "",
      sign_receipt: "",
      clothes_image: [],
      finished_images_url: [],

      completeCode: "",
      theme: {
        title: "签收单",
        viceTitle: "",
      },
      otherCodeShow: false,
      userPhone: "",
      showKeyboard: false,
    };
  },
  components: {
    UploadDate,
    SelectWrap,
    FileUpdate,
    FinishedList,
    FormUpload,
  },
  // watch: {
  //   url(newName, oldName) {
  //     this.url = newName;
  //   },
  // },
  created() {
    this.orderId = this.$route.params.orderId;
  },
  // activated() {
  //   if (window.sessionStorage.electronListUrl != undefined) {
  //     this.url =
  //       this.cdnHttp +
  //       window.sessionStorage.getItem("electronListUrl") +
  //       "?imageView2/1/format/webp/w/90/h/180/q/90";
  //   }
  // },
  methods: {
    getFinishImagesUrl(e) {
      this.finished_images_url = e;
      console.log("完工照上传", this.finished_images_url);
    },

    linkAaffirmFinished(e) {
      this.page_show = "affirm-main";
      this.electronListUrl = e;
    },
    tempLinkMain() {
      this.page_show = "affirm-main";
    },
    listPreview() {
      ImagePreview({
        images: [`${this.cdnHttp}${this.electronListUrl}`],
        closeable: true,
        showIndex: false,
      });
    },
    go() {
      this.$router.go(-1);
      this.electronListUrl = "";
    },
    replaceList() {
      this.$http
        .put("/api/v1/cancel_signfor", {
          order_id: this.$route.params.orderId,
        })
        .then((res) => {
          this.electronListUrl = "";
        })
        .catch((err) => {
          console.log(err);
        });
      this.url = "";
    },
    getVideoId(e) {
      this.video = e;
    },
    getImageList(e) {
      console.log("完工照片", e);
      this.images = e;
    },
    getCloseImage(e) {
      this.clothes_image = e;
      console.log("正装照列表", e);
    },
    getIsFormaFormData() {},
    getFormData(e) {
      console.log("ff", e);
    },
    // 提交确认完工
    submitEleList() {
      if (this.finished_images_url.length < 2) {
        this.$toast("完工照片至少上传2张");
      } else if (this.isFormal == "") {
        this.$toast("请填写是否穿正装选项");
      } else if (this.isFormal == "1" && this.clothes_image == []) {
        this.$toast("请上传着装照片");
      } else if (this.sign_receipt == 0) {
        this.$toast("请选择服务签收单类型");
      } else if (this.electronListUrl == "") {
        this.$toast("请上传服务签收单");
      } else if (this.completeCode == "") {
        this.$toast("请填写客户确认验收码");
      } else {
        this.$http
          .post(`api/v2/worker/order/${this.$route.params.orderId}/finished`, {
            finished_images_url: this.finished_images_url.join(","),
            video: this.video,
            whether_work_clothes: this.isFormal,
            clothes_image: this.clothes_image.join(","),
            sign_receipt_type: this.sign_receipt,
            sign_for_image_url: this.electronListUrl,
            confirm_code: this.completeCode,
          })
          .then((res) => {
            this.$toast.success("完工成功");
            this.goSubPageR("order");
          })
          .catch((err) => {
            this.$toast(err.error);
          });
      }
    },
    goList() {
      this.sign_receipt = 1;
      this.page_show = "finished-list";
      // this.goSubPage("finished_list", this.$route.params.orderId);
    },

    uploadPageList(e) {
      this.sign_receipt = 2;
      let imgFormData = new FormData();
      if (e.length > 1) {
        for (let i = 0; i < e.length; i++) {
          imgFormData.append(`files[${i}]`, e[i].file);
        }
      } else {
        imgFormData.append(`files[0]`, e.file);
      }
      imgFormData.append("order_id", this.$route.params.orderId);
      imgFormData.append("scence", 3);
      imgFormData.append("file_type", 1);
      this.loading = false;
      this.$http
        .post("/api/worker/uploadFinished", imgFormData, {
          headers: {
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.$toast("纸质签收单上传成功");
          console.log("上传纸质签收单", res);
          this.electronListUrl = res.data.result[0].url;
          this.loading = true;
        });
    },
    getSelectResult(e) {
      this.selectStatu = e.contact_status;
    },
    readExample() {
      ImagePreview({
        images: [
          "https://cdn.yishifu.com/image/20201105141804dbVUnnOG.jpg?imageView2/1/format/webp/q/90",
          "https://cdn.yishifu.com/image/20201105141830A90fljD2.png?imageView2/1/format/webp/q/90",
        ],
        closeable: true,
      });
    },
    getCompleteCode() {
      this.noCodeShow = true;
      this.$http
        .get(`/api/v2/worker/order/${this.$route.params.orderId}/confirm_code`)
        .then((res) => {
          this.$toast("验收码已发送至客户");
          this.otherCodeShow = false;
        })
        .catch((err) => {
          this.$toast(err.error);
        });
    },
    getOtherCode() {
      this.$http
        .get(`api/v2/worker/order/${this.$route.params.orderId}/contact_mobile`)
        .then((res) => {
          console.log(res);
          this.userPhone = res.data.mobile;
        });
      this.otherCodeShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
#completeAlready {
  padding-bottom: 18rem;
  .van-popup,
  .van-popup--bottom {
    background: @themeBacColor;
  }
  .noCompleteCode {
    font-size: 1.4rem;
    text-align: center;
    background: #fff;
    padding: 1rem 0;
    color: @secondaryTone;
  }
  .sign_receipt {
    display: flex;
    padding: 1rem;
    .selectList {
      font-size: 1.6rem;
      color: #c2c2c2;
      border: 1px solid #c2c2c2;
      margin: 2rem;
      padding: 1rem 0;
      border-radius: 6px;
      width: 46%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .sign_receiptImg {
    position: relative;
    display: flex;
    padding: 1rem;
    .replace {
      position: absolute;
      right: 2rem;
      top: 0;
    }
  }
  .otherCodeWrap {
    width: 32rem;
    border-radius: 8px;
    box-sizing: border-box;
    background: #f1f0f5;
    padding: 5rem 2rem;
    img {
      width: 50%;
    }
    .tip {
      font-size: 1.2rem;
      text-align: center;
    }
    .codeType {
      box-sizing: border-box;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      margin: 1.8rem 0;
      position: relative;
      padding: 1rem 2rem;
      overflow: hidden;
      .line {
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        margin: 1rem 0;
      }
    }
    .codeType .type1::after {
      content: "方法1";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      background: #189afb;
      padding: 0 2rem;
      transform: translate(-1.5rem, 0.7rem) rotate(-40deg);
      color: #fff;
      font-size: 1.2rem;
    }
    .type2::after {
      content: "方法2";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      background: #ff976a;
      padding: 0 2rem;
      transform: translate(-1.5rem, 0.7rem) rotate(-40deg);
      color: #fff;
      font-size: 1.2rem;
    }
  }
}
</style>