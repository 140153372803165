<template>
  <div id="electronListTable" v-if="dataAlready">
    <TopBar style="background: #FF816C">
      <template #leftInfo>
        <div @click="cancel">
          <svg t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24">
            <path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#fff"></path>
          </svg>
        </div>
      </template>
      <template #middleInfo>
        <div class="searchBar">
          <p style="color: #fff">服务签收单(电子版)</p>
        </div>
      </template>
      <template #rightInfo>
        <div class="newMessage"></div>
      </template>
    </TopBar>
    <div class="listTableWrap" ref="listTableWrap">
      <div class="tableHeader">
        <img :src="`${cdnHttp}/image/20201109174104tS92I90E.png?imageView2/1/format/webp/w/180/h/40/q/100`">
        <p>服务签收单(电子档）</p>
      </div>
      <van-cell-group>
        <van-cell title="订单编号" :value="electronList.order_sn" />
        <van-cell title="客户" :value="electronList.name" />
        <van-cell title="服务类型" :value="electronList.service_mode" />
        <van-cell center title="安装产品" :value="electronList.intact == 1? '是':'否'" label="是否正常使用" />
        <van-cell center title="安装产品" :value="electronList.damage == 1? '是':'否'" label="否存在刮痕，损坏" />
        <van-cell title="完成情况" :value="electronList.done == 1? '已完成':'未完成'" />
        <van-cell title="备注栏" :value="electronList.remarks" />
        <van-cell title="客户评价">
          <template #right-icon>
            <div>
              <van-rate v-model="electronList.score" :count="5" size="1.8rem" color="#F8843E" allow-half readonly />
              <p style="font-size: 1.3rem">非常满意</p>
            </div>
          </template>
        </van-cell>
        <van-cell title="客户签名">
          <template #right-icon>
            <div>
              <img :src="`${cdnHttp}/${electronList.signature}?imageView2/1/format/webp/w/40/h/130/q/80`" alt="">
              <p v-if="electronList.created_at"></p>
              <!-- {{getMateDate(electronList.created_at)}} -->
            </div>
          </template>
        </van-cell>
        <div class="listTableTips">
          <p>签收说明</p>
          <ol>
            <li>1.客户/代签人签字或者盖章后，视为对以上内容完全同意</li>
            <li>2.请在服务完成并且检查无误后确认签收</li>
            <li>3.当服务完成时，若需要该师傅再次上门，请在最终服务完成后签收。</li>
            <li>4.当服务未完成时，若不需要该师傅再次上门，备注未完成原因并签收</li>
          </ol>
        </div>
      </van-cell-group>
    </div>
    <div class="bottomButton">
      <div class="">
        <van-button block @click="cancel">取消</van-button>
      </div>
      <div>
        <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="confirm">
          确定
        </van-button>
      </div>

    </div>
    <div class="upLoading" v-show="upLoadingShow">
      <van-loading size="24px" vertical>电子签收单生成中...</van-loading>
    </div>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import TopBar from "@/components/home/TopBar.vue";
import Loading from "@/components/effect/Loading.vue";
export default {
  components: {
    TopBar,
    Loading,
  },
  data() {
    return {
      upLoadingShow: false,
      imgURL: "",
      startValue: 4.5,
      electronList: {},
      dataAlready: false,
      intact: {
        1: "是",
        2: "否",
      },
      damage: {
        1: "是",
        2: "否",
      },
      done: {
        1: "是",
        2: "否",
      },
    };
  },

  created() {
    this.$http
      .get(`/api/worker/signFor/${this.$route.params.orderId}`, {
        headers: {
          Authorization: `bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log("电子表信息", res);
        this.electronList = res.data;
        this.dataAlready = true;
      });
  },
  mounted() {},
  update() {},
  methods: {
    confirm() {
      this.$dialog
        .confirm({
          title: "确认信息",
          message: "是否确认服务签收单\n信息内容准确无误？",
          cancelButtonText: "再看看",
          confirmButtonText: "我确认",
        })
        .then(() => {
          this.changeImage();
        })
        .catch(() => {
          // on cancel
        });
    },
    cancel() {
      this.goSubPageR("finished_list", this.$route.params.orderId);
    },
    changeImage() {
      let imgHeight = this.$refs["listTableWrap"].offsetHeight; // 获取DOM高度
      let imgWidth = this.$refs["listTableWrap"].offsetWidth; // 获取DOM宽度
      // console.log(imgHeight, imgWidth);
      let scale = window.devicePixelRatio; // 获取设备像素比
      // 优化截图不全
      window.pageYOffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      //
      html2canvas(this.$refs.listTableWrap, {
        backgroundColor: null, //设置背景颜色
        useCORS: true, //允许图片跨域
        scale: scale, //缩放2倍，使得图片更加清晰
        width: imgWidth,
        height: imgHeight,
        imageTimeout: 5000, //设置图片的超时，设置0为禁用
        proxy: "", //url代理，用于加载跨域图源，为空则不会加载
        ignoreElements: (element) => {
          //用于忽略转换的图片中不需要的匹配元素，注意，为true才不会转换
          if (element.id == "mytitle") {
            return true;
          }
        },
      }).then((canvas) => {
        let imgURL = canvas.toDataURL("image/png");
        this.imgURL = imgURL;
        // console.log(this.imgURL);
        // base64转为文件格式并且上传服务器
        let imgFile = this.convertBase64UrlToBlob(this.imgURL);
        let mgFormDate = new FormData();
        mgFormDate.append("image", imgFile, Math.random() * 1000 + ".jpg");
        mgFormDate.append("order_id", this.$route.params.orderId);
        mgFormDate.append("sign_type", 1);
        this.upLoadingShow = true;
        this.$http
          .post("/api/v1/sign_receipt", mgFormDate, {
            headers: {
              Authorization: `bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            // console.log("上传带签名的验收单", res);
            this.$toast.success("提交成功");
            this.upLoadingShow = false;
            // this.$store.commit('changeElectronListUrl',99)
            window.sessionStorage.setItem(
              "electronListUrl",
              `${res.data.result.path}`
            );
            this.goSubPageR("affirm-finished", this.$route.params.orderId);
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
#electronListTable {
  margin: 55px 0 55px 0;
  .listTableWrap {
    width: 95%;
    margin: 2px auto;
    box-sizing: border-box;
    background: #fff;
    padding: 0 1rem;
    .tableHeader {
      padding: 1.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 1.6rem;
      p {
        padding: 1rem;
      }
    }
    .listTableTips {
      padding: 2.5rem 1rem;
      color: #888788;
      line-height: 1.8rem;
      font-size: 1.2rem;
      p {
        font-size: 1.5rem;
        padding: 1rem 0;
        color: #323233;
      }
    }
  }
  .bottomButton {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: space-around;
    div {
      flex: 1;
      font-size: 1.4rem;
    }
  }
  .upLoading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>