<template>
  <div id="signaTure">
    <TopBar style="background: #FF816C">
      <template #leftInfo>
        <div @click="returnLast">
          <svg t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24">
            <path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#fff"></path>
          </svg>
        </div>
      </template>
      <template #middleInfo>
        <div class="searchBar">
          <p style="color: #fff">签收单签字板</p>
        </div>
      </template>
      <template #rightInfo>
        <span class="clear" @click="clearCtx">清除</span>
        <span class="sure" @click="sureCtx">确认</span>
      </template>
    </TopBar>
    <div class="signaTureTip">
      <van-notice-bar scrollable text="请确认服务签收单内容再签字，签字即证明您已确认服务完工没有异议" />
    </div>
    <canvas ref="canvas" @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend">sss</canvas>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
export default {
  components: {
    TopBar,
  },
  data() {
    return {
      ctx: "",
      point: {
        x: 0,
        y: 0,
      },
      linePoint: {
        x: 0,
        y: 0,
      },
      pageW: 0,
      pageH: 0,
      startX: 0,
      startY: 0,
      moveX: 0,
      moveY: 0,
      startObj: {},
      moving: true,
      canvas: "",
      signaPageInfo: {
        resultInfo: {},
        signaPageShow: false,
      },
    };
  },
  mounted() {
    var evt = "onorientationchange" in window ? "orientationchange" : "resize";
    this.canvas = this.$refs["canvas"];
    this.pageW = document.documentElement.clientWidth;
    this.pageH = document.documentElement.clientHeight;
    this.canvas.width = this.pageW;
    this.canvas.height = this.pageH;
    this.ctx = this.canvas.getContext("2d");
    this.ctx.strokeStyle = "#000";
    this.ctx.lineWidth = 3;
    // 解决画布导出为黑色
    this.ctx.fillStyle = "#fff";
    this.ctx.fillRect(0, 0, 1000, 1000);
  },
  methods: {
    // 定义画布的宽高
    getWH() {},
    touchstart(e) {
      console.log(e);
      this.moving = true;

      if (this.moving) {
        this.point.x = e.touches[0].clientX;
        this.point.y = e.touches[0].clientY - 80;
        console.log(this.point.y);
        this.ctx.beginPath();
      }
    },
    touchmove(e) {
      if (this.moving) {
        this.linePoint.x = e.touches[0].clientX;
        this.linePoint.y = e.touches[0].clientY - 80;
        this.ctx.moveTo(this.point.x, this.point.y);
        this.ctx.lineTo(this.linePoint.x, this.linePoint.y);
        this.ctx.stroke();
        this.point.x = this.linePoint.x;
        this.point.y = this.linePoint.y;
      }
    },
    touchend() {
      if (this.moving) {
        this.ctx.closePath(); // 停止绘制
        this.moving = false; // 关闭绘制开关
      }
    },
    clearCtx() {
      this.ctx.clearRect(0, 0, 1000, 1000);
    },
    returnLast() {
      this.$emit("getSignaPageInfo", this.signaPageInfo);
    },
    sureCtx() {
      this.canvasToImage(this.ctx);
      this.signaPageInfo.signaPageShow = false;
      this.$emit("getSignaPageInfo", this.signaPageInfo);
    },
    canvasToImage() {
      let image = new Image();
      let imageUrl = this.canvas.toDataURL("image/jpeg");
      image = imageUrl.replace(/^data:image\/(jpg);base64,/, "");
      console.log(image);
      // 调用 base64 改 file 函数
      let imgFile = this.convertBase64UrlToBlob(imageUrl);
      console.log(imgFile, 99);
      const imgFormDate = new FormData();
      imgFormDate.append("signature_image", imgFile, Date.now() + ".jpg");
      imgFormDate.append("order_id", this.$route.params.orderId);
      // 传给后台
      this.$http
        .post("api/v2/resource/signfor/image/signature", imgFormDate)
        .then((res) => {
          console.log(res);
          this.signaPageInfo.resultInfo = res;
        })
        .catch((err) => {
          this.$toast('签字失败')
          console.log(err);
        });
    },
   
  },
};
</script>

<style lang="less">
#signaTure {
  .signaTrueButton {
    background: #fff;
    height: 50px;
    text-align: center;
  }
  margin-top: 45px;
  .title {
    font-size: 1.6rem;
  }
  .clear,
  .sure {
    margin-right: 1rem;
    color: #fff;
  }
  .signaTureTip {
    p {
      font-size: 1.4rem;
      text-align: center;
    }
  }
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  canvas {
    background: #fff;
  }
}
</style>