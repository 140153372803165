<template>
  <div id="uploadDate">
    <Loading :show="!uploadDateReady || !uploadDateVReady" title="上传中" />
    <p class="uploadDateTitle">
      <slot name="uploadTiele">
      </slot>
      <span>
        <slot name="uploadTiele1">
        </slot>
      </span>
    </p>
    <div class="van-hairline--bottom"></div>
    <div class="selectUpload">
      <div class="ListLeft">
        <van-uploader max-count="6" :after-read="afterRead" multiple>
          <svg t="1604042891392" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3236" width="30" height="30">
            <path d="M567.466667 634.325333v234.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-234.666667H413.866667a8.533333 8.533333 0 0 1-6.826667-13.653333l110.933333-147.925333a8.533333 8.533333 0 0 1 13.653334 0l110.933333 147.925333a8.533333 8.533333 0 0 1-6.826667 13.653333h-68.266666z" fill="#707070" p-id="3590"></path>
            <path d="M768 725.333333a128 128 0 0 0 38.613333-250.112l-39.850666-12.586666-14.506667-39.253334a256.128 256.128 0 0 0-480.554667 0l-14.464 39.253334-39.850666 12.586666A128.085333 128.085333 0 0 0 256 725.333333a42.666667 42.666667 0 0 1 0 85.333334 213.333333 213.333333 0 0 1-64.341333-416.810667 341.461333 341.461333 0 0 1 640.682666 0A213.418667 213.418667 0 0 1 768 810.666667a42.666667 42.666667 0 0 1 0-85.333334z" fill="#707070" p-id="3591"></path>
          </svg>
        </van-uploader>
        <p class="typeTitle">上传照片</p>
      </div>
      <div class="ListRight">
        <div class="imgItem" v-for="(item,index) in uploadImageList" :key="index" :style="`background: url('${cdnHttp}${item}?imageView2/1/format/webp/w/110/h/110/q/90') no-repeat center / cover`" @click="imagePreview(index)">
          <div class="replace" @click="replaceList(item)">
            <svg t="1605075812806" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1672" width="16" height="16">
              <path d="M874.666667 791.466667L571.733333 490.666667 874.666667 189.866667c17.066667-17.066667 17.066667-44.8 0-59.733334-17.066667-17.066667-44.8-17.066667-59.733334 0L512 430.933333 211.2 128c-17.066667-14.933333-44.8-14.933333-61.866667 0s-14.933333 44.8 0 61.866667L452.266667 490.666667 149.333333 791.466667c-17.066667 17.066667-17.066667 44.8 0 59.733333 17.066667 17.066667 44.8 17.066667 59.733334 0L512 550.4 812.8 853.333333c17.066667 17.066667 44.8 17.066667 59.733333 0 17.066667-17.066667 17.066667-44.8 2.133334-61.866666z" p-id="1673" fill="#ffffff"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="selectUpload" v-show="upLoadInfo.needVideo">
      <div class="ListLeft">
        <van-uploader max-count="6" :after-read="afterReadRadio" multiple accept="video/*">
          <svg t="1604053282932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3589" width="30" height="30">
            <path d="M567.466667 634.325333v234.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-234.666667H413.866667a8.533333 8.533333 0 0 1-6.826667-13.653333l110.933333-147.925333a8.533333 8.533333 0 0 1 13.653334 0l110.933333 147.925333a8.533333 8.533333 0 0 1-6.826667 13.653333h-68.266666z" fill="#707070" p-id="3590"></path>
            <path d="M768 725.333333a128 128 0 0 0 38.613333-250.112l-39.850666-12.586666-14.506667-39.253334a256.128 256.128 0 0 0-480.554667 0l-14.464 39.253334-39.850666 12.586666A128.085333 128.085333 0 0 0 256 725.333333a42.666667 42.666667 0 0 1 0 85.333334 213.333333 213.333333 0 0 1-64.341333-416.810667 341.461333 341.461333 0 0 1 640.682666 0A213.418667 213.418667 0 0 1 768 810.666667a42.666667 42.666667 0 0 1 0-85.333334z" fill="#707070" p-id="3591"></path>
          </svg>
        </van-uploader>
        <p class="typeTitle">{{typeTitle? '上传视频':'重新上传'}}</p>
      </div>
      <!-- <div class="ListRight" v-if="uploadDateVReady">
        <p style="font-size:1.4rem">{{tipMessage}}</p>
      </div>
      <div class="ListRight" v-else>
        <van-loading type="spinner" color="#ff816c" />
      </div> -->
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import Loading from "@/components/public/animation/Loading";
export default {
  props: {
    upLoadInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      typeTitle: true,
      fileList: [],
      uploadImageList: [],
      videoId: "",
      uploadDateReady: true,
      uploadDateVReady: true,
      tipMessage: "",
    };
  },
  components: {
    Loading,
  },
  created() {
    console.log(this.upLoadInfo);
    // console.log(this.$route);
    // console.log(this.cdnHttp);
  },
  methods: {
    imagePreview(index) {
      let _iamgeList = [];
      this.uploadImageList.forEach((item) => {
        _iamgeList.push(`${this.cdnHttp}${item}`);
      });
      console.log(_iamgeList);
      ImagePreview({
        images: _iamgeList,
        startPosition: index,
        closeable: true,
      });
    },

    replaceList(index) {
      this.uploadImageList.remove(index);
      console.log(this.uploadImageList);
    },
    afterRead(file, type) {
      this.uploadDateReady = false;
      let imageFormData = new FormData();
      console.log(file);
      if (file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          imageFormData.append(`files[${i}]`, file[i].file);
        }
      } else {
        imageFormData.append(`files[0]`, file.file);
      }
      imageFormData.append("file_type", 1);
      imageFormData.append("order_id", this.upLoadInfo.orderId);
      imageFormData.append("scence", this.upLoadInfo.scence);
      this.$http
        .post(this.upLoadInfo.httpUrl, imageFormData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res, "ssss");
          console.log(res.data.result);
          // console.log(res.data.result.url);
          res.data.result.forEach((item, index) => {
            this.uploadImageList.push(item.url);
          });
          this.$emit("getImageList", this.uploadImageList);
          this.uploadDateReady = true;
        });
    },
    afterReadRadio(file) {
      this.uploadDateVReady = false;
      this.typeTitle = false;
      let videoFormDate = new FormData();
      console.log(file);
      videoFormDate.append(`files[0]`, file.file);
      videoFormDate.append("order_id", this.upLoadInfo.orderId);
      videoFormDate.append("type", 2);
      videoFormDate.append("scence", this.upLoadInfo.scence);
      this.$http
        .post("/api/worker/uploadInspection", videoFormDate, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data.result[0].id);
          this.$emit("getVideoId", res.data.result[0].id);
          this.uploadDateVReady = true;
          this.tipMessage = "视频上传成功";
        })
        .catch((err) => {
          console.log(err);
          this.videoId = err;
        });
    },
  },
};
</script>
<style lang="less" scoped>
#uploadDate {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  .uploadDateTitle {
    font-size: 1.4rem;
    padding: 1.2rem 0;
    text-align: center;
    span {
      font-size: 1.4rem;
      color: #b0b0b0;
      padding-left: 0.85rem;
    }
  }
  .selectUpload {
    padding: 1rem;
    display: flex;
    align-items: center;
    .ListLeft {
      padding-right: 1rem;
      width: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .ListRight {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      align-items: center;
      padding: 0.5rem 0;
      .imgItem {
        position: relative;

        flex: 0 0 auto;

        width: 9rem;
        height: 9rem;
        margin-right: 0.6rem;
        border-radius: 4px;
        .replace {
          width: 1.6rem;
          height: 1.6rem;
          background: #0009;
          border-radius: 100%;
          position: absolute;
          right: -0.6rem;
          top: -0.09rem;
          z-index: 99;
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 5rem;
          // height: 5rem;
          // background: saddlebrown;
        }
      }
    }
    .ListRight::-webkit-scrollbar {
      width: 0px;
    }
    .typeTitle {
      font-size: 1.4rem;
      padding: 0.5rem 0;
    }
  }
}
</style>