<template>
  <div class="fileUpdate">
    <van-uploader :after-read="onRead">
      <div class="upDateBac onLoading" v-if="front_show">
        <van-loading />
      </div>
      <div v-else class="upDateBacWrap">
        <div class="upDateBac">
          <svg t="1604042891392" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3236" width="30" height="30">
            <path d="M567.466667 634.325333v234.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-234.666667H413.866667a8.533333 8.533333 0 0 1-6.826667-13.653333l110.933333-147.925333a8.533333 8.533333 0 0 1 13.653334 0l110.933333 147.925333a8.533333 8.533333 0 0 1-6.826667 13.653333h-68.266666z" fill="#707070" p-id="3590"></path>
            <path d="M768 725.333333a128 128 0 0 0 38.613333-250.112l-39.850666-12.586666-14.506667-39.253334a256.128 256.128 0 0 0-480.554667 0l-14.464 39.253334-39.850666 12.586666A128.085333 128.085333 0 0 0 256 725.333333a42.666667 42.666667 0 0 1 0 85.333334 213.333333 213.333333 0 0 1-64.341333-416.810667 341.461333 341.461333 0 0 1 640.682666 0A213.418667 213.418667 0 0 1 768 810.666667a42.666667 42.666667 0 0 1 0-85.333334z" fill="#707070" p-id="3591"></path>
          </svg>
          <p>上传着装照</p>
        </div>
        <div class="upDateBac" :style="front_path"></div>
      </div>

    </van-uploader>
  </div>
</template>
<script>
export default {
  props: {
    upLoadInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      front_show: false,
      front_path: "",
    };
  },
  methods: {
    onRead(file) {
      this.front_show = true;
      let image = new FormData();
      image.append("image", file.file);
      image.append("file_type", this.upLoadInfo.fileType);
      image.append("order_id", this.upLoadInfo.orderId);
      image.append("scence", this.upLoadInfo.scence);
      this.$http
        .post(this.upLoadInfo.httpUrl, image, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.front_path = `background:url(${this.cdnHttp}/${res.data.result.url}) no-repeat center / cover`;
          this.front_image = res.data.result.id;
          this.front_show = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.fileUpdate {
  width: 100%;
  height: 10rem;
  &.onLoading {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100%;
  }
  .upDateBacWrap {
    display: flex;
    padding: 1rem;
  }
  .upDateBac {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 1.4rem;
      padding: 1.2rem 0;
      text-align: center;
    }
  }
}
</style>